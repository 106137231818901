<template>
    <div class="menu-items">
        <router-link tag="div" to="/recharge" class="m-item mrecharge"></router-link>
        <router-link tag="div" to="/exchange" class="m-item mexchange"></router-link>
        <router-link tag="div" to="/sports" class="m-item msports"></router-link>
        <router-link tag="div" to="/sports_inplay" class="m-item minplay"></router-link>
        <router-link tag="div" to="/casinohonor" class="m-item mcasino"></router-link>
        <router-link tag="div" :to="{path:'/leisure/bet365_superleague', query: {t: new Date().getTime()}}" class="m-item mb365soccer"></router-link>
        <router-link tag="div" :to="{path:'/leisure/bet365_water', query: {t: new Date().getTime()}}" class="m-item mb365basketball"></router-link>
        <router-link tag="div" :to="{path:'/leisure/bet365_horserace2', query: {t: new Date().getTime()}}" class="m-item mb365horse"></router-link>
        <router-link tag="div" :to="{path:'/leisure/eospowerball1m', query: {t: new Date().getTime()}}" class="m-item mpowerball"></router-link>
        <router-link tag="div" :to="{path:'/leisure/speedgamepdali', query: {t: new Date().getTime()}}" class="m-item mpigsd"></router-link>
        <router-link tag="div" :to="{path:'/leisure/speedgamelm', query: {t: new Date().getTime()}}" class="m-item mlm"></router-link>
        <router-link tag="div" :to="{path:'/leisure/speedgamesplit', query: {t: new Date().getTime()}}" class="m-item msplitbaccara"></router-link>
        <router-link tag="div" :to="{path:'/leisure/ssd1', query: {t: new Date().getTime()}}" class="m-item mstarsd"></router-link>
        <router-link tag="div" :to="{path:'/leisure/pandacoin', query: {t: new Date().getTime()}}" class="m-item mpandacoin"></router-link>
        <router-link tag="div" :to="{path:'/leisure/pandasadali', query: {t: new Date().getTime()}}" class="m-item mpandasd"></router-link>
        <router-link tag="div" :to="{path:'/leisure/minionsrullet', query: {t: new Date().getTime()}}" class="m-item mminionsrullet"></router-link>
        <router-link tag="div" :to="{path:'/leisure/minionssadali', query: {t: new Date().getTime()}}" class="m-item mminionssd"></router-link>
        <router-link tag="div" :to="{path:'/tgame365/tgame365highlow', query: {t: new Date().getTime()}}" class="m-item mtokenhilow"></router-link>
        <router-link tag="div" :to="{path:'/tgame365/tgame365ruollet', query: {t: new Date().getTime()}}" class="m-item mtokenrullet"></router-link>
        <router-link tag="div" :to="{path:'/tgame365/tgame365baccarat', query: {t: new Date().getTime()}}" class="m-item mtokenbaccarat"></router-link>
        <router-link tag="div" :to="{path:'/leisure/jwcasinoangel', query: {t: new Date().getTime()}}" class="m-item mjwangel"></router-link>
        <router-link tag="div" :to="{path:'/leisure/jwcasinoost', query: {t: new Date().getTime()}}" class="m-item mjwost"></router-link>
        <router-link tag="div" :to="{path:'/leisure/jwcasinosupermario', query: {t: new Date().getTime()}}" class="m-item mjwmario"></router-link>
        <router-link tag="div" :to="{path:'/leisure/candy_dragontiger', query: {t: new Date().getTime()}}" class="m-item mdragontiger"></router-link>
        <router-link tag="div" to="/coupon" class="m-item mcoupon"></router-link>
        <router-link tag="div" to="/culcheck" class="m-item mculcheck"></router-link>
        <router-link tag="div" to="/sports_result" class="m-item mgameresult"></router-link>
        <router-link tag="div" to="/message" class="m-item mmessage"></router-link>

        <router-link tag="div" to="/notice" class="m-item mnotice"></router-link>
        <router-link tag="div" to="/eventlist" class="m-item mevent"></router-link>
        <router-link tag="div" to="/sports_betinfo" class="m-item mbetinfo"></router-link>
        <router-link tag="div" to="/customer_center" class="m-item mcustomer"></router-link>

        <router-link tag="div" to="" class="m-item mcommingsoon"></router-link>
        <router-link tag="div" to="" class="m-item mcommingsoon"></router-link>
        <router-link tag="div" to="" class="m-item mcommingsoon"></router-link>
        <router-link tag="div" to="" class="m-item mcommingsoon"></router-link>
        <router-link tag="div" to="" class="m-item mcommingsoon"></router-link>
        <router-link tag="div" to="" class="m-item mcommingsoon"></router-link>
        <router-link tag="div" to="" class="m-item mcommingsoon"></router-link>
        <router-link tag="div" to="" class="m-item mcommingsoon"></router-link>


    </div>
</template>

<script>
    export default {
        name: "MobileMenuItemsComp"
    }
</script>

<style scoped>

    .menu-items {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-content: start;
        flex-wrap: wrap;
        height: 910px;
    }

    /*.menu-items img{*/
    /*    width: 98%;*/
    /*    margin: 5px;*/
    /*    border: 1px solid #ffa500;*/
    /*}*/
    .m-item {
        width: 24%;
        height: 100px;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        position: relative;
        border-radius: 5px;
    }

    .m-item .item1 {
        width: 100%;
        height: 58px;
        position: absolute;
        left: 50%;
        top: 50%;
        overflow: hidden;
        transform: translateX(-50%) translateY(-50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        box-sizing: border-box;
        padding-left: 20px;
    }

    .m-item .item2 {
        width: 100%;
        height: 58px;
        background-color: #1b1a1a;
        opacity: .7;
        position: absolute;
        left: 50%;
        top: 50%;
        overflow: hidden;
        transform: translateX(-50%) translateY(-50%);
    }

    .m-item .item1 .txt1 {
        font-size: 10px;
        color: #c4c4c3;
    }

    .m-item .item1 .txt2 {
        font-size: 14px;
        font-weight: 600;
        color: #fff9ff;
        padding: 3px 0;
    }

    .mrecharge {
        background: url(../assets/images/mobile/menu/recharge.png) center center no-repeat;
        background-size: 100%;
    }

    .mexchange {
        background: url(../assets/images/mobile/menu/exchange.png) center center no-repeat;
        background-size: 100%;
    }

    .minplay {
        background: url(../assets/images/mobile/menu/inplay.png) center center no-repeat;
        background-size: 100%;
    }

    .msports {
        background: url(../assets/images/mobile/menu/sports.png) center center no-repeat;
        background-size: 100%;
    }
    .mcasino {
        background: url(../assets/images/mobile/menu/casino.png) center center no-repeat;
        background-size: 100%;
    }
    .mb365soccer{
        background: url(../assets/images/mobile/menu/b365soccer.png) center center no-repeat;
        background-size: 100%;
    }
    .mb365basketball {
        background: url(../assets/images/mobile/menu/b365basketball.png) center center no-repeat;
        background-size: 100%;
    }
    .mb365horse {
        background: url(../assets/images/mobile/menu/b365horse.png) center center no-repeat;
        background-size: 100%;
    }

    .mpowerball {
        background: url(../assets/images/mobile/menu/powerball.png) center center no-repeat;
        background-size: 100%;
    }
    .mpigsd {
        background: url(../assets/images/mobile/menu/pigsd.png) center center no-repeat;
        background-size: 100%;
    }
    .mlm {
        background: url(../assets/images/mobile/menu/lm.png) center center no-repeat;
        background-size: 100%;
    }
    .msplitbaccara {
        background: url(../assets/images/mobile/menu/splitbaccara.png) center center no-repeat;
        background-size: 100%;
    }
    .mstarsd {
        background: url(../assets/images/mobile/menu/starsd.png) center center no-repeat;
        background-size: 100%;
    }
    .mpandacoin {
        background: url(../assets/images/mobile/menu/pandacoin.png) center center no-repeat;
        background-size: 100%;
    }
    .mpandasd {
        background: url(../assets/images/mobile/menu/padasd.png) center center no-repeat;
        background-size: 100%;
    }
    .mminionsrullet {
        background: url(../assets/images/mobile/menu/minionsrullet.png) center center no-repeat;
        background-size: 100%;
    }
    .mminionssd {
        background: url(../assets/images/mobile/menu/minionssd.png) center center no-repeat;
        background-size: 100%;
    }
    .mtoken {
         background: url(../assets/images/mobile/menu/token.png) center center no-repeat;
         background-size: 100%;
     }
    .mtokenhilow {
        background: url(../assets/images/mobile/menu/tk-hilow.png) center center no-repeat;
        background-size: 100%;
    }
    .mtokenrullet {
        background: url(../assets/images/mobile/menu/tk-rullet.png) center center no-repeat;
        background-size: 100%;
    }
    .mtokenbaccarat {
        background: url(../assets/images/mobile/menu/tk-baccara.png) center center no-repeat;
        background-size: 100%;
    }
    .mjwangel {
        background: url(../assets/images/mobile/menu/jw-angel.png) center center no-repeat;
        background-size: 100%;
    }
    .mjwost {
        background: url(../assets/images/mobile/menu/jw-ost.png) center center no-repeat;
        background-size: 100%;
    }
    .mjwmario {
        background: url(../assets/images/mobile/menu/jw-mario.png) center center no-repeat;
        background-size: 100%;
    }
    .mdragontiger {
        background: url(../assets/images/mobile/menu/drgontiger.png) center center no-repeat;
        background-size: 100%;
    }
    .mcoupon {
        background: url(../assets/images/mobile/menu/coupon.png) center center no-repeat;
        background-size: 100%;
    }
    .mnotice {
        background: url(../assets/images/mobile/menu/notice.png) center center no-repeat;
        background-size: 100%;
    }
    .mevent {
        background: url(../assets/images/mobile/menu/event.png) center center no-repeat;
        background-size: 100%;
    }
    .mbetinfo {
        background: url(../assets/images/mobile/menu/betinfo.png) center center no-repeat;
        background-size: 100%;
    }
    .mcustomer {
        background: url(../assets/images/mobile/menu/customercenter.png) center center no-repeat;
        background-size: 100%;
    }
    .mculcheck {
        background: url(../assets/images/mobile/menu/culcheck.png) center center no-repeat;
        background-size: 100%;
    }
    .mgameresult {
        background: url(../assets/images/mobile/menu/gameresulet.png) center center no-repeat;
        background-size: 100%;
    }
    .mmessage {
        background: url(../assets/images/mobile/menu/message.png) center center no-repeat;
        background-size: 100%;
    }
    .mcommingsoon{
        background: url(../assets/images/mobile/menu/commingsoon.png) center center no-repeat;
        background-size: 100%;
    }



</style>