<template>

    <div id="app" class="wrapper">
        <router-view v-wechat-title="$route.meta.title" :key="$route.fullPath"></router-view>
        <loading-comp v-if="$store.state.isLoading"></loading-comp>
        <loading-inplay-comp v-if="$store.state.iild"></loading-inplay-comp>
<!--        <login-comp></login-comp>-->
<!--        <register-comp></register-comp>-->
    </div>

</template>

<style>
    @import url("assets/css/base.css");
    @import url("assets/css/common.css");
    @import url("assets/css/style.css");
    @import url("assets/css/media.css");
    @import url("assets/css/sports.css");
</style>
<script>
    import TopbarComp from "./components/TopbarComp";
    import FootComp from "./components/FootComp";
    import LoadingComp from "./components/LoadingComp";
    import {RECEIVE_HIDE_LOADING, RECEIVE_HIDE_LOADING_INPLAY} from "./store/mutation-types";
    import {bustabitCash2totoCash} from "./network/bustabitRequest";
    import LoadingInplayComp from "./components/LoadingInplayBetComp";
    import LoginComp from "./components/LoginComp";
    import Register from "./views/user/Register";
    import RegisterComp from "./components/RegisterComp";

    export default {
        components: {RegisterComp, Register, LoginComp, LoadingInplayComp, LoadingComp, FootComp, TopbarComp},
        data() {
            return {}
        },
        created() {
            if (sessionStorage.getItem('state')) {
                this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('state'))))
            }
            window.addEventListener('pagehide', (ev) => {
                this.$store.commit(RECEIVE_HIDE_LOADING_INPLAY)
                this.$store.commit(RECEIVE_HIDE_LOADING)
                this.$store.state.sportsCartItems = []
                //window.sessionStorage.clear();
                //sessionStorage.setItem('state', '')
                sessionStorage.setItem('state', JSON.stringify(this.$store.state))
                return false
            })
        },
    }
</script>
<style scoped>

</style>