<template>
    <div class="banner">
        <div>
            <a href="https://t.me/wine365" target="_blank">
                <img alt="" src="../assets/images/banner/dst/tel.png" style="width: 100%">
            </a>
        </div>
        <div  style="margin-top: 10px">
            <a href="http://와인주소.com" target="_blank">
                <img alt="" src="../assets/images/banner/dst/com.png" style="width: 100%">
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RightBarBannerComp"
    }
</script>

<style scoped>

    .banner{
        width: 100%;
        padding: 5px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
</style>