<template>
<!--    <span>{{time}}</span>-->
</template>

<script>
    export default {
        name: "Clock",
        data() {
            return {
                time: '',
                timeInteval: null,
            }
        },
        methods: {
            setTime() {
                this.time = this.$moment().format("HH:mm:ss");
            }
        },
        created() {
            this.setTime()
            this.timeInteval = setInterval(() => {
                this.setTime()
            }, 1000)
        },
        beforeDestroy() {
            if (null != this.timeInteval) {
                clearInterval(this.timeInteval)
                this.timeInteval = null
            }
        }
    }
</script>

<style scoped>

</style>