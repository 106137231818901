<template>
    <div>

        <popup-comp></popup-comp>

        <!--읽지 않은 메세지-->
        <message-comp></message-comp>


        <div class="footer">
            <div class="footer_thirdparty" style="">
                <div class="th_item">
                    <img src="../assets/images/logo/thirdparty/cq9_logo.png">
                </div>
                <div class="th_item">
                    <img src="../assets/images/logo/thirdparty/dreamtech_logo.png">
                </div>
                <div class="th_item">
                    <img src="../assets/images/logo/thirdparty/ori_logo.png">
                </div>
                <div class="th_item">
                    <img src="../assets/images/logo/thirdparty/asian_logo.png">
                </div>

                <div class="th_item">
                    <img src="../assets/images/logo/thirdparty/aristo_logo.png">
                </div>
                <div class="th_item">
                    <img src="../assets/images/logo/thirdparty/bbin_logo.png">
                </div>

                <div class="th_item">
                    <img src="../assets/images/logo/thirdparty/midas_logo.png">
                </div>
                <div class="th_item">
                    <img src="../assets/images/logo/thirdparty/micro_logo.png">
                </div>
                <div class="th_item">
                    <img src="../assets/images/logo/thirdparty/tpg_logo.png">
                </div>
                <div class="th_item">
                    <img src="../assets/images/logo/thirdparty/tishan_logo.png">
                </div>

            </div>
        </div>

    </div>


</template>

<script>
    import MessageComp from "./MessageComp";
    import PopupComp from "./PopupComp";

    export default {
        name: "FootComp",
        components: {PopupComp, MessageComp},
        created() {
        }
    }
</script>

<style scoped>
    .footer {
        width: 100%;
        padding: 0px 340px 16px 340px;
        color: #fff9ff;
        --border-top: 1px solid #353535;
        --background: var(--footBg);
    }


    .footer .footer_cont img{
        max-height: 100px;
    }
    .footer .footer_cont .footer_bottom .bitem p{
        padding: 3px 0;
        color: #a3a1a7;
    }


    .footer .footer_thirdparty{
        display: flex;align-items: center;justify-content: space-evenly;
        box-sizing: border-box;
        padding: 10px;
        flex-wrap: nowrap;
        border-top: 1px solid #686767;
    }
    .footer .footer_thirdparty .th_item{
        width: 8%;
        text-align: center;
    }
    .footer .footer_thirdparty .th_item img{
        width: 90%;
    }

</style>