<template>
    <div class="header">
        <div class="small-menu">
            <div class="sm-left">
                <div @click="initUserInfo">
                    <img :src="$store.state.userInfo.rank.imageUrl">
                    <span style="color: #bdbdbd;margin-left: 5px;font-weight: bold">{{$store.state.userInfo.nickname}}</span>
                </div>
                <div @click="initUserInfo">
                    <img src="../assets/images/icon/common/icon-cash.png" alt="">
                    <span style="color: #bdbdbd;margin-left: 5px;font-weight: bold">머니: {{$store.state.userInfo.cash|comma}}</span>
                </div>
                <div @click="changePoint">
                    <img src="../assets/images/icon/common/icon-point.png" alt="">
                    <span style="color: #bdbdbd;margin-left: 5px;font-weight: bold">포인트: {{$store.state.userInfo.userPoint|comma}} </span>
                </div>
                <div @click="refreshCasinoCash">
                    <img src="../assets/images/icon/common/icon-casinochip.png" alt="">
                    <span style="color: #bdbdbd;margin-left: 5px;font-weight: bold">카지노: {{$store.state.userInfo.casinocash|comma}} </span>
                </div>
            </div>
            <div class="sm-right">
                <router-link tag="div" to="" @click.native="changePoint" class="sm-right-button">포인트전환</router-link>
                <div class="dota"></div>
                <!--<router-link tag="div" to="/event" class="sm-right-button">이벤트</router-link>
                <div class="dota"></div>-->
                <router-link tag="div" to="/eventlist" class="sm-right-button">이벤트</router-link>
                <div class="dota"></div>
                <router-link tag="div" to="/coupon" class="sm-right-button">쿠폰</router-link>
                <div class="dota"></div>
                <router-link tag="div" to="/message" class="sm-right-button">쪽지</router-link>
                <div class="dota"></div>
                <router-link tag="div" to="/culcheck" class="sm-right-button">출석부</router-link>
                <div class="dota"></div>
                <router-link tag="div" to="/user_info" class="sm-right-button">회원정보</router-link>
                <div class="dota"></div>
                <router-link tag="div" to="" @click.native="logout" class="sm-right-button">로그아웃</router-link>
            </div>
        </div>
        <div class="logo-panel">
            <div class="logo-panel-left"></div>
            <div class="logo-panel-middle">
                <div class="logo">
                    <router-link :to="{path: '/main', query: {t: new Date().getTime()}}" tag="div" class="logo-img"><img src="../assets/images/logo/dst/logo01.png" alt=""></router-link>
                    <div class="apply-button">
                        <router-link tag="button" to="/recharge">충전</router-link>
                        <router-link tag="button" to="/exchange">환전</router-link>
                    </div>
                    <div class="mobile-user-menu" @click="rightBarHandle">
                        <img src="../assets/images/menu/mobile-menu-w.png" alt="">
                    </div>
                </div>
                <div class="menu">
                    <router-link tag="div" to="/sports">스포츠</router-link>
                    <router-link tag="div" to="/sports_inplay">인플레이</router-link>
                    <router-link tag="div" :to="{path: '/leisure/bet365_superleague', query: {t: new Date().getTime()}}" >미니게임</router-link>
                    <router-link tag="div" :to="{path: '/tgame365/tgame365highlow', query: {t: new Date().getTime()}}">토큰게임</router-link>
                    <router-link tag="div" :to="{path: '/casinohonor', query: {t: new Date().getTime()}}" >카지노&슬롯</router-link>
                    <router-link tag="div" to="/sports_betinfo">베팅내역</router-link>
                    <router-link tag="div" to="/sports_result">경기결과</router-link>
                    <router-link tag="div" to="/notice">공지사항</router-link>
                    <router-link tag="div" to="/eventlist">이벤트</router-link>
                    <router-link tag="div" to="/customer_center">고객센터</router-link>
<!--                  -->
                </div>
                <div class="mobile-menu">
                    <div class="mm01">
                        <div style="width: 140px">
                            <img :src="$store.state.userInfo.rank.imageUrl">
                            <span style="color: floralwhite;margin-left: 5px">{{$store.state.userInfo.nickname}}</span>
                        </div>
                        <div style="color: #fff9ff">
                                쪽지: {{$store.state.uncheckedMessageCount}}
                        </div>
                    </div>
                    <div class="mm02">
                        <div>
                            머니: <span>{{$store.state.userInfo.cash|comma}}</span>
                        </div>
                        <div  @click="changePoint">
                            포인트: <span>{{$store.state.userInfo.userPoint|comma}}</span>
                        </div>
                        <div>
                            카지노: <span>{{$store.state.userInfo.casinocash|comma}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="logo-panel-right"></div>
        </div>

        <!--모바일 메뉴-->
<!--        <mobile-top-menu></mobile-top-menu>-->
    </div>
</template>

<script>
    import sportsConst from "../common/sportsConst";
    import leisureConst from "../common/leisureConst";
    import MobileTopMenu from "./mobile/MobileTopMenu";
    import MobileTopUserInfo from "./mobile/MobileTopUserInfo";
    import {changePoint, getLineMessage, getSiteStatus, logout} from "../network/userRequest";
    import {INIT_USERINFO, RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../store/mutation-types";
    import {getAccountBalance} from "../network/casinoHonorRequest";

    export default {
        name: "TopbarComp",
        components: {MobileTopUserInfo, MobileTopMenu},
        data() {
            return {
                leisureConst,
                cross: sportsConst.GAME_DISPLAYPOSITION_1x2,
                handicap: sportsConst.GAME_DISPLAYPOSITION_HANDICAPOVERUNDER,
                special: sportsConst.GAME_DISPLAYPOSITION_SPECIAL,
                live: sportsConst.GAME_DISPLAYPOSITION_LIVE,
                lineMessage: '',
            }
        },
        methods: {
            initUserInfo() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                this.$store.dispatch('actionUserInfo')
                setTimeout(() => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                }, 1000)
            },
            changePoint() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                changePoint().then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    if (!res.data.success) {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                    this.initUserInfo()
                })
            },
            refreshCasinoCash() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                getAccountBalance().then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    this.$set(this.$store.state.userInfo, 'casinocash', res.data.data)
                })
            },
            rightBarHandle() {
                this.$bus.$emit('rightBarHandle', true)
            },
            showLoginPanel() {
                if (!this.$store.state.isLogin && !this.$store.state.isShowLoginPanel) {
                    this.$store.state.isShowLoginPanel = true
                    this.$store.state.isShowRegisterPanel = false
                }
            },
            showRegisterPanel() {
                if (!this.$store.state.isLogin) {
                    this.$store.state.isShowRegisterPanel = true
                    this.$store.state.isShowLoginPanel = false
                }
            },
            changeSportsDisplay(disp) {
                //SportsOriginal.vue -> $on
                //SportsLinksComp.vue ->on
                this.$bus.$emit('sportsDisplayChanged', disp);
            },
            showMobileTopMenu() {
                //모바일 메뉴아이콘 클릭시
                //MobileTopMenu.vue에서 $on
                this.$bus.$emit('mobileTopMenuActive')
            },
            showMobileUserInfo() {
                //모바일 유저아이콘 클릭시
                //MobileTopUserInfo.vue에서 $on
                this.$bus.$emit('mobileTopUserInfoActive')
            },
            logout() {
                this.$swal({
                    title: '로그아웃 하시겠습니까 ?',
                    type: 'question',
                    confirmButtonText: '로그아웃',
                }).then((res) => {
                    if (res.value) {
                        this.$store.commit(INIT_USERINFO);
                        window.sessionStorage.clear();
                        this.$router.replace("/login")
                        this.$store.state.uncheckedMessageCount = 0;
                        logout();
                    }
                })

            },
            toMain() {
                this.$router.replace("/main")
            },
            getLineMessage() {
                getLineMessage().then(res => {
                    if (res.data.success) {
                        let lines = res.data.data;
                        lines.map(line => {
                            this.lineMessage = this.lineMessage + '   ' + line.content
                        })
                    }

                })
            },
            browser() {
                let u = navigator.userAgent
                let app = navigator.appVersion
                return {         //移动终端浏览器版本信息
                    trident: u.indexOf('Trident') > -1, //IE
                    presto: u.indexOf('Presto') > -1, //opera
                    webKit: u.indexOf('AppleWebKit') > -1, //Apple,Google
                    gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //FireFox
                    mobile: !!u.match(/AppleWebKit.*Mobile.*/), //
                    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //
                    android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //
                    iPhone: u.indexOf('iPhone') > -1, //
                    iPad: u.indexOf('iPad') > -1, //
                    webApp: u.indexOf('Safari') == -1//
                }
            }
        },
        created() {
            this.$store.state.ismobile = this.browser().mobile
            //랭킹,보너스,베팅금액,당첨금액 등 설정요청
            this.$store.dispatch('actionCommonConfig')
            this.getLineMessage();
            getSiteStatus().then(res => {
                if (!res.data.success) {
                    this.$store.state.isMaintaining = true;
                    this.$store.state.maintainingMessage = res.data.msg
                    this.$store.state.isLogin = false;
                    this.$store.state.isShowLoginPanel = true;
                    logout()
                } else {
                    this.$store.state.isMaintaining = false;
                    this.$store.state.maintainingMessage = ''
                }
            })
            if (this.$store.state.isLogin) {
                this.$store.state.uncheckedMessageCount = 0;
                this.$store.dispatch('actionUserInfo')
                this.$store.dispatch('actionUnCheckedMessageCount')
                setTimeout(() => {
                    getAccountBalance().then(res => {
                        this.$set(this.$store.state.userInfo, 'casinocash', res.data.data)
                    })
                }, 500)
            } else {
                this.$store.commit(INIT_USERINFO);
                this.$router.replace("/login")
            }
            getSiteStatus().then(res => {
                if (!res.data.success) {
                    this.$router.replace("/login")
                }
            })
        },
    }
</script>

<style scoped>
    .header {
        width: 100%;
        height: 150px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        margin-top: 0;
        z-index: 1001;
    }

    .header .small-menu {
        width: 100%;
        box-sizing: border-box;
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        background: linear-gradient(180deg, #020202, #393939);
    }

    .header .small-menu .sm-left {
        width: 50%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        box-sizing: border-box;
        padding-left: 20px;
    }

    .header .small-menu .sm-left div {
        width: 120px;
        cursor: pointer;
    }

    .header .small-menu .sm-left img {
        width: 20px;
        height: 20px;
        vertical-align: middle;
    }

    .header .small-menu .sm-right {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: nowrap;
        box-sizing: border-box;
        padding-right: 10px;
    }

    .header .small-menu .sm-right .sm-right-button {
        height: 28px;
        line-height: 28px;
        color: #bebebe;
        text-align: center;
        cursor: pointer;
        font-weight: bold;
    }
    .header .small-menu .sm-right .sm-right-button:hover {
        color: #ffffff;
        transition: .3s;
    }

    .header .small-menu .sm-right .dota {
        width: 1px;
        height: 10px;
        color: #c9c9c9;
        background-color: #787878;
        margin: 0 20px;
    }

    .header .logo-panel{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        box-sizing: border-box;
    }
    .header .logo-panel .logo-panel-left{
        width: 340px;
    }
    .header .logo-panel .logo-panel-right{
        width: 340px;
    }
    .header .logo-panel .logo-panel-middle{
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 10px 0;
    }
    .header .logo-panel .logo-panel-middle .logo{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
    }
    .header .logo-panel .logo-panel-middle .logo .logo-img{
        width: 33%;
        text-align: left;
        cursor: pointer;
    }
    .header .logo-panel .logo-panel-middle .logo .logo-img img{
        height: 80px;
    }
    .header .logo-panel .logo-panel-middle .logo .apply-button{
        width: 33%;
        justify-content: center;
        align-items: center;
        display: none;
    }
    .header .logo-panel .logo-panel-middle .logo .apply-button button{
        height: 35px;
        background: linear-gradient(180deg, #515151, #6a6969,#515151);
        border-radius: 3px;
        padding: 0 15px;
        margin: 0 5px;
        font-size: 14px;
        font-weight: bold;
    }
    .header .logo-panel .logo-panel-middle .logo .mobile-user-menu{
        width: 33%;
        display: none;
        justify-content: flex-end;
        align-items: center;
        box-sizing: border-box;
        padding-right: 10px;
    }
    .header .logo-panel .logo-panel-middle .logo .mobile-user-menu img{
        height: 40px;
    }
    .header .logo-panel .logo-panel-middle .menu{
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: nowrap;
        border-radius: 5px;
        border: 1px solid #808080;
        margin-top: 5px;
        background: linear-gradient(180deg, #030303, #3d3d3d);

    }
    .header .logo-panel .logo-panel-middle .menu div{
        color: #c7c7c7;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
        padding: 15px 20px;
    }
    .header .logo-panel .logo-panel-middle .menu div:hover{
        color: #fff9ff;
        transition: .3s;
    }
    .header .logo-panel .logo-panel-middle .mobile-menu{
        display: none;
    }


    @media screen and (max-width: 1024px) {
        .header{
            height: 140px!important;
        }
        .header .small-menu {
            display: none;
        }
        .header .logo-panel {
            border-bottom: 1px solid #4a4a4c;
            height: 160px!important;
            padding: 5px 2px!important;
        }
        .header .logo-panel .logo-panel-left {
            display: none;
        }
        .header .logo-panel .logo-panel-middle {
            width: 100%;
            padding: 0!important;
            display: flex;
            flex-direction: column;
        }
        .header .logo-panel .logo-panel-right {
            display: none;
        }
        .header .logo-panel .logo-panel-middle .menu {
            display: none;
        }
        .header .logo-panel .logo-panel-middle .logo{
            margin-bottom: 10px;
        }
        .header .logo-panel .logo-panel-middle .logo .logo-img{
            width: 45%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        .header .logo-panel .logo-panel-middle .logo .apply-button{
            width: 40%;
            height: 45px!important;
            display: flex!important;
            justify-content: flex-start;
            align-items: center;
        }
        .header .logo-panel .logo-panel-middle .logo .mobile-user-menu{
            width: 15%;
            height: 45px;
            display: flex!important;

        }
        .header .logo-panel .logo-panel-middle .logo .logo-img img{
            height: 45px;
        }
        .header .logo-panel .logo-panel-middle .logo .mobile-user-menu img {
            height:30px!important;
        }
        .header .logo-panel .logo-panel-middle .mobile-menu{
            background: linear-gradient(180deg, #030303, #3d3d3d);
            width: 100%;
            height: 80px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: nowrap;
        }
        .header .logo-panel .logo-panel-middle .mobile-menu .mm01{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: nowrap;
        }
        .header .logo-panel .logo-panel-middle .mobile-menu .mm01 img{
            width: 30px;
        }
        .header .logo-panel .logo-panel-middle .mobile-menu .mm02{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            color: #fff9ff;
        }
        .header .logo-panel .logo-panel-middle .mobile-menu .mm02 div{
            width: 100px;
            box-sizing: border-box;
            padding: 5px ;
        }
        .header .logo-panel .logo-panel-middle .mobile-menu .mm02 div span{
            color: #f9c36b;
        }



    }
</style>

