import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const Login = () => import('../views/user/Login');
const Register = () => import('../views/user/Register');
const Main = () => import('../views/user/Main');
const SportsOriginal = () => import('../views/sports/SportsOriginal');
const SportsOriginalHandicapOverUnder = () => import('../views/sports/SportsOriginalHandicapOverUnder');
const SportsOriginalSpecial = () => import('../views/sports/SportsOriginalSpecial');
const SportsGameResult = () => import('../views/sports/SportsGameResult');
const SportsBetInfo = () => import('../views/sports/SportsBetInfo');
const UserInfo = () => import('../views/user/UserInfo');
const CustomerCenter = () => import('../views/user/CustomerCenter');
const Board = () => import('../views/user/Board');
const Notice = () => import('../views/user/Notice');
const BetRule = () => import('../views/user/BetRule');
const BoardDetail = () => import('../views/user/BoardDetail');
const EventDetail = () => import('../views/user/EventDetail');
const CulChek = () => import('../views/user/CulCheck');
const Rullet = () => import('../views/user/Rullet');
const Coupon = () => import('../views/user/Coupon');
const LiveTV = () => import('../views/user/LiveTV');
const Recharge = () => import('../views/user/Recharge');
const Exchange = () => import('../views/user/Exchange');
const Message = () => import('../views/user/Message');

const LeisureGame = () => import('../views/leisuregame/LeisureGame');
const LeisureGameBetInfo = () => import('../views/leisuregame/LeisrueGameBetInfo');
const LeisureGameResult = () => import('../views/leisuregame/LeisureGameResult');
const CasinoSlotGame = () => import('../views/casino/CasinoSlotGame');
const SportsInplay = () => import('../views/inplay/SportsInplay');
const EventList = () => import('../views/user/EventList');

const Tgame365 = () => import('../views/leisuregame/Tgame365');
const ThirdPartGameBetInfo = () => import('../views/casino/ThirdpartGameBetInfo');
const Tgame365CasinoSlotGame = () => import('../views/casino/Tgame365CasinoSlotGame');
const CasinoSlotGameHonor = () => import('../views/casino/CasinoSlotGameHonor');

const NoteLoginAsk = () => import('../views/user/NotLoginAsk');

const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            title: '로그인'
        }
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
            title: '회원가입'
        }
    },
    {
        path: '/notloginask',
        name: 'notloginask',
        component: NoteLoginAsk,
        meta: {
            title: '비회원문의'
        }
    },
    {
        path: '/main',
        name: 'main',
        component: Main,
        meta: {
            title: '메인'
        }
    },
    {
        path: '/sports',
        name: 'sports',
        component: SportsOriginal,
        meta: {
            title: '스포츠'
        }
    },
    {
        path: '/sports_ho',
        name: 'sportsho',
        component: SportsOriginalHandicapOverUnder,
        meta: {
            title: '스포츠'
        }
    },
    {
        path: '/sports_special',
        name: 'sportsspecial',
        component: SportsOriginalSpecial,
        meta: {
            title: '스포츠'
        }
    },
    {
        path: '/sports_inplay',
        name: 'sports_inplay',
        component: SportsInplay,
        meta: {
            title: 'Inplay'
        }
    },
    {
        path: '/sports_result',
        name: 'sports_result',
        component: SportsGameResult,
        meta: {
            title: 'Sports경기결과'
        }
    },
    {
        path: '/leisure_result',
        name: 'leisure_result',
        component: LeisureGameResult,
        meta: {
            title: 'Sports경기결과'
        }
    },
    {
        path: '/sports_betinfo',
        name: 'sports_betinfo',
        component: SportsBetInfo,
        meta: {
            title: '베팅내역(Sports)'
        }
    },
    {
        path: '/leisure_betinfo',
        name: 'leisure_betinfo',
        component: LeisureGameBetInfo,
        meta: {
            title: '베팅내역(Mini)'
        }
    },
    {
        path: '/customer_center',
        name: 'customer_center',
        component: CustomerCenter,
        meta: {
            title: '1:1문의'
        }
    },
    {
        path: '/board',
        name: 'board',
        component: Board,
        meta: {
            title: '게시판'
        }
    },
    {
        path: '/board_detail/:id',
        name: 'boardDetail',
        component: BoardDetail,
        meta: {
            title: '게시판'
        }
    },
    {
        path: '/notice',
        name: 'notice',
        component: Notice,
        meta: {
            title: '공지사항'
        }
    },
    {
        path: '/betrule',
        name: 'betrule',
        component: BetRule,
        meta: {
            title: '베팅규정'
        }
    },

    {
        path: '/eventlist',
        name: 'eventlist',
        component: EventList,
        meta: {
            title: '이벤트'
        }
    },
    {
        path: '/event_detail/:id',
        name: 'eventDetail',
        component: EventDetail,
        meta: {
            title: '이벤트'
        }
    },
    {
        path: '/recharge',
        name: 'recharege',
        component: Recharge,
        meta: {
            title: '충전'
        }
    },
    {
        path: '/exchange',
        name: 'exchange',
        component: Exchange,
        meta: {
            title: '환전'
        }
    },
    {
        path: '/message',
        name: 'message',
        component: Message,
        meta: {
            title: '쪽지'
        }
    },
    {
        path: '/user_info',
        name: 'user_info',
        component: UserInfo,
        meta: {
            title: '내정보'
        }
    },
    {
        path: '/casino',
        name: 'CasinoSlotGame',
        component: CasinoSlotGame,
        meta: {
            title: 'Casino&SlotGame'
        }
    },
    {
        path: '/casinohonor',
        name: 'CasinoSlotGameHonor',
        component: CasinoSlotGameHonor,
        meta: {
            title: 'Casino&SlotGame'
        }
    },
    {
        path: '/tgame365casino',
        name: 'Tgame365CasinoSlotGame',
        component: Tgame365CasinoSlotGame,
        meta: {
            title: 'Tgame365 Casino&SlotGame'
        }
    },
    {
        path: '/livetv',
        name: 'livetv',
        component: LiveTV,
        meta: {
            title: 'Live TV'
        }
    },
    {
        path: '/culcheck',
        name: 'culcheck',
        component: CulChek,
        meta: {
            title: '출석체크'
        }
    },
    {
        path: '/rullet',
        name: 'rullet',
        component: Rullet,
        meta: {
            title: '룰렛'
        }
    },
    {
        path: '/coupon',
        name: 'coupon',
        component: Coupon,
        meta: {
            title: '쿠폰'
        }
    },
    {
        path: '/thirdpartgame_betinfo',
        name: 'thirdpartgame_betinfo',
        component: ThirdPartGameBetInfo,
        meta: {
            title: '카지노&슬롯'
        }
    },
    {
        path: '/tgame365',
        name: 'tgame365',
        component: Tgame365,
        children: [
            {
                path: '/',
                redirect: 'tgame365highlow'
            },
            {
                path: 'tgame365highlow',
                name: 'leisure_detail_tgame365_highlow',
                component: resolve => require(['../views/leisuregame/children/Tgame365HighLow'], resolve),
                meta: {
                    title: '토큰게임 하이로'
                }
            },
            {
                path: 'tgame365fw',
                name: 'leisure_detail_tgame365_fw',
                component: resolve => require(['../views/leisuregame/children/Tgame365FW'], resolve),
                meta: {
                    title: '토큰게임 포츈휠'
                }
            },
            {
                path: 'tgame365ruollet',
                name: 'leisure_detail_tgame365_ruollet',
                component: resolve => require(['../views/leisuregame/children/Tgame365Ruollet'], resolve),
                meta: {
                    title: '토큰게임 룰렛'
                }
            },
            {
                path: 'tgame365slot',
                name: 'leisure_detail_tgame365_slot',
                component: resolve => require(['../views/leisuregame/children/Tgame365Slot'], resolve),
                meta: {
                    title: '토큰게임 슬롯'
                }
            },
            {
                path: 'tgame365snail3',
                name: 'leisure_detail_tgame365_snail3',
                component: resolve => require(['../views/leisuregame/children/Tgame365Snail3'], resolve),
                meta: {
                    title: '달팽이 3줄'
                }
            },
            {
                path: 'tgame365snail4',
                name: 'leisure_detail_tgame365_snail4',
                component: resolve => require(['../views/leisuregame/children/Tgame365Snail4'], resolve),
                meta: {
                    title: '달팽이 4줄'
                }
            },
            {
                path: 'tgame365baccarat',
                name: 'leisure_detail_tgame365_baccarat',
                component: resolve => require(['../views/leisuregame/children/Tgame365Baccarat'], resolve),
                meta: {
                    title: '바카라'
                }
            }
        ]
    },
    {
        path: '/leisure',
        name: 'leisure',
        component: LeisureGame,
        children: [
            {
                path: '/',
                redirect: 'bet365_premiership'
            },
            {
                path: 'powersadali',
                name: 'leisure_detail_powersadali',
                component: resolve => require(['../views/leisuregame/children/PowerSadali'], resolve),
                meta: {
                    title: '파워사다리'
                }
            },
            {
                path: 'speedkino',
                name: 'leisure_detail_speedkino',
                component: resolve => require(['../views/leisuregame/children/SpeedKino'], resolve),
                meta: {
                    title: '스피드키노'
                }
            },
            {
                path: 'kinosadali',
                name: 'leisure_detail_kinosadali',
                component: resolve => require(['../views/leisuregame/children/KinoSadali'], resolve),
                meta: {
                    title: '키노 사다리'
                }
            },
            {
                path: 'ssd1',
                name: 'leisure_detail_ssd1',
                component: resolve => require(['../views/leisuregame/children/StarSadali1'], resolve),
                meta: {
                    title: '별다리 1분'
                }
            },
            {
                path: 'ssd2',
                name: 'leisure_detail_ssd2',
                component: resolve => require(['../views/leisuregame/children/StarSadali2'], resolve),
                meta: {
                    title: '별다리 2분'
                }
            },
            {
                path: 'ssd3',
                name: 'leisure_detail_ssd3',
                component: resolve => require(['../views/leisuregame/children/StarSadali3'], resolve),
                meta: {
                    title: '별다리 3분'
                }
            },
            {
                path: 'nameddalidali',
                name: 'leisure_detail_nameddalidali',
                component: resolve => require(['../views/leisuregame/children/NamedDalidali'], resolve),
                meta: {
                    title: '네임드 다리다리'
                }
            },
            {
                path: 'namedsadali',
                name: 'leisure_detail_namedsadali',
                component: resolve => require(['../views/leisuregame/children/NamedSadali'], resolve),
                meta: {
                    title: '네임드 사다리'
                }
            },
            {
                path: 'namedspeedsadali',
                name: 'leisure_detail_namedspeedsadali',
                component: resolve => require(['../views/leisuregame/children/NamedSpeedSadali'], resolve),
                meta: {
                    title: '네임드 스피드 사다리'
                }
            },
            {
                path: 'namedsnail',
                name: 'leisure_detail_namedsnail',
                component: resolve => require(['../views/leisuregame/children/NamedSnail'], resolve),
                meta: {
                    title: '네임드 달팽이'
                }
            },
            {
                path: 'fx1m',
                name: 'leisure_detail_fx1m',
                component: resolve => require(['../views/leisuregame/children/Fx1m'], resolve),
                meta: {
                    title: 'FX 1M'
                }
            },
            {
                path: 'fx2m',
                name: 'leisure_detail_fx2m',
                component: resolve => require(['../views/leisuregame/children/Fx2m'], resolve),
                meta: {
                    title: 'FX 2M'
                }
            },
            {
                path: 'fx3m',
                name: 'leisure_detail_fx3m',
                component: resolve => require(['../views/leisuregame/children/Fx3m'], resolve),
                meta: {
                    title: 'FX 3M'
                }
            },
            {
                path: 'fx4m',
                name: 'leisure_detail_fx4m',
                component: resolve => require(['../views/leisuregame/children/Fx4m'], resolve),
                meta: {
                    title: 'FX 4M'
                }
            },
            {
                path: 'fx5m',
                name: 'leisure_detail_fx5m',
                component: resolve => require(['../views/leisuregame/children/Fx5m'], resolve),
                meta: {
                    title: 'FX 5M'
                }
            },
            {
                //Bet365SoccerPremiership
                path: 'bet365_premiership',
                name: 'leisure_detail_bet365_premiership',
                component: resolve => require(['../views/leisuregame/children/Bet365SoccerPremiership'], resolve),
                meta: {
                    title: 'Bet365 프리미어'
                }
            },
            {
                //Bet365SoccerSuperLeague
                path: 'bet365_superleague',
                name: 'leisure_detail_bet365_superleague',
                component: resolve => require(['../views/leisuregame/children/Bet365SoccerSuperLeague'], resolve),
                meta: {
                    title: 'Bet365 슈퍼리그'
                }
            },
            {
                //Bet365SoccerEuroCup
                path: 'bet365_eurocup',
                name: 'leisure_detail_bet365_eurocup',
                component: resolve => require(['../views/leisuregame/children/Bet365SoccerEuroCup'], resolve),
                meta: {
                    title: 'Bet365 유로컵'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'bet365_worldcup',
                name: 'leisure_detail_bet365_worldcup',
                component: resolve => require(['../views/leisuregame/children/Bet365SoccerWorldCup'], resolve),
                meta: {
                    title: 'Bet365 월드컵'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'bet365_goldenhill',
                name: 'leisure_detail_bet365_goldenhill',
                component: resolve => require(['../views/leisuregame/children/Bet365DogRaceGoldenHill'], resolve),
                meta: {
                    title: 'Bet365 개경주 골든힐'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'bet365_hillside',
                name: 'leisure_detail_bet365_hillside',
                component: resolve => require(['../views/leisuregame/children/Bet365DogRaceHillSide'], resolve),
                meta: {
                    title: 'Bet365 개경주 힐사이드'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'bet365_horserace',
                name: 'leisure_detail_horserace',
                component: resolve => require(['../views/leisuregame/children/Bet365HorseRace'], resolve),
                meta: {
                    title: '경마 트렌담 파크'
                }
            },
            {
                //
                path: 'bet365_horserace2',
                name: 'leisure_detail_horserace2',
                component: resolve => require(['../views/leisuregame/children/Bet365HorseRace2'], resolve),
                meta: {
                    title: '경마 브리타니아 웨이'
                }
            },
            {
                path: 'bet365_horserace3',
                name: 'leisure_detail_horserace3',
                component: resolve => require(['../views/leisuregame/children/Bet365HorseRace3'], resolve),
                meta: {
                    title: '경마 페스티벌 다운즈'
                }
            },
            {
                path: 'bet365_horserace4',
                name: 'leisure_detail_horserace4',
                component: resolve => require(['../views/leisuregame/children/Bet365HorseRace4'], resolve),
                meta: {
                    title: '경마 빅토리아 파크'
                }
            },
            {
                //Bet365SoccerSuperLeague
                path: 'bet365_water',
                name: 'leisure_detail_bet365_warter',
                component: resolve => require(['../views/leisuregame/children/Bet365BasketBallWaterLeague'], resolve),
                meta: {
                    title: 'Bet365 워터포트'
                }
            },
            {
                //Bet365SoccerSuperLeague
                path: 'bet365_baker',
                name: 'leisure_detail_bet365_baker',
                component: resolve => require(['../views/leisuregame/children/Bet365BasketBallBakerLeague'], resolve),
                meta: {
                    title: 'Bet365 워터포트'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'powerball',
                name: 'leisure_detail_powerball',
                component: resolve => require(['../views/leisuregame/children/Powerball'], resolve),
                meta: {
                    title: '파워볼'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'powerball_lotto',
                name: 'leisure_detail_powerball_lotto',
                component: resolve => require(['../views/leisuregame/children/PowerballLotto'], resolve),
                meta: {
                    title: '파워볼 로또'
                }
            }
            ,
            {
                //크라운 섯다
                path: 'sutda',
                name: 'leisure_detail_sutda',
                component: resolve => require(['../views/leisuregame/children/CrownSutda'], resolve),
                meta: {
                    title: '크라운 섯다'
                }
            },
            {
                //크라운 나인볼
                path: 'nineball',
                name: 'leisure_detail_nineball',
                component: resolve => require(['../views/leisuregame/children/CrownNineBall'], resolve),
                meta: {
                    title: '크라운 섯다'
                }
            },
            {
                //로투스 바카라
                path: 'lotus_bakara',
                name: 'leisure_detail_lotus_bakara',
                component: resolve => require(['../views/leisuregame/children/LotusBakara'], resolve),
                meta: {
                    title: '로투스 바카라'
                }
            },
            {
                //로투스 바카라
                path: 'lotus_oddeven',
                name: 'leisure_detail_lotus_oddeven',
                component: resolve => require(['../views/leisuregame/children/LotusOddEven'], resolve),
                meta: {
                    title: '로투스 홀짝'
                }
            },
            {
                //로투스 바카라
                path: 'lotus_dragontiger',
                name: 'leisure_detail_lotus_dragontiger',
                component: resolve => require(['../views/leisuregame/children/LotusDragonTiger'], resolve),
                meta: {
                    title: '로투스 드래곤타이거'
                }
            },
            {
                //sky speed 바카라
                path: 'sky_speed_bakara',
                name: 'leisure_detail_sky_speed_bakara',
                component: resolve => require(['../views/leisuregame/children/SkySpeedBakara'], resolve),
                meta: {
                    title: '스카이파크 Speed 바카라'
                }
            },
            {
                //sky 홀짝
                path: 'sky_oddeven',
                name: 'leisure_detail_sky_oddeven',
                component: resolve => require(['../views/leisuregame/children/SkyOddEven'], resolve),
                meta: {
                    title: '스카이파크 홀짝'
                }
            },
            {
                //sky 주사위
                path: 'sky_dice',
                name: 'leisure_detail_sky_dice',
                component: resolve => require(['../views/leisuregame/children/SkyDice'], resolve),
                meta: {
                    title: '스카이파크 주사위'
                }
            },
            {
                //mgm ghfwkr
                path: 'mgm_bakara',
                name: 'leisure_detail_mgm_bakara',
                component: resolve => require(['../views/leisuregame/children/MgmBakara'], resolve),
                meta: {
                    title: 'mgm 바카라'
                }
            },
            {
                //mgm ghfwkr
                path: 'mgm_oddeven',
                name: 'leisure_detail_mgm_oddeven',
                component: resolve => require(['../views/leisuregame/children/MgmOddEven'], resolve),
                meta: {
                    title: 'mgm 홀짝'
                }
            },
            {
                //dragon tiger
                path: 'candy_dragontiger',
                name: 'leisure_detail_candy_dragontigera',
                component: resolve => require(['../views/leisuregame/children/CandyDragonTiger'], resolve),
                meta: {
                    title: '드래곤타이거'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'eospowerball1m',
                name: 'leisure_detail_eospowerball1m',
                component: resolve => require(['../views/leisuregame/children/EOSPowerBall1M'], resolve),
                meta: {
                    title: 'EOS 파워볼 1분'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'eospowerball3m',
                name: 'leisure_detail_eospowerball3m',
                component: resolve => require(['../views/leisuregame/children/EOSPowerBall3M'], resolve),
                meta: {
                    title: 'EOS 파워볼 3분'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'eospowerball5m',
                name: 'leisure_detail_eospowerball5m',
                component: resolve => require(['../views/leisuregame/children/EOSPowerBall5M'], resolve),
                meta: {
                    title: 'EOS 파워볼 5분'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'hspowerball3m',
                name: 'leisure_detail_hspowerball3m',
                component: resolve => require(['../views/leisuregame/children/HSPowerBall3M'], resolve),
                meta: {
                    title: '하운슬로 파워볼 3분'
                }
            },
            {
                //Bet365SoccerWorldCup
                path: 'hspowerball5m',
                name: 'leisure_detail_hspowerball5m',
                component: resolve => require(['../views/leisuregame/children/HSPowerBall5M'], resolve),
                meta: {
                    title: '하운슬로 파워볼 5분'
                }
            },
            {
                path: 'speedgamepdali',
                name: 'leisure_detail_speedgamepdali',
                component: resolve => require(['../views/leisuregame/children/SpeedGamePDALI'], resolve),
                meta: {
                    title: '황금돼지 사다리'
                }
            },
            {
                path: 'speedgamelm',
                name: 'leisure_detail_speedgamelm',
                component: resolve => require(['../views/leisuregame/children/SpeedGameLM'], resolve),
                meta: {
                    title: '라이언&무지'
                }
            },
            {
                path: 'speedgamesplit',
                name: 'leisure_detail_speedgamesplit',
                component: resolve => require(['../views/leisuregame/children/SpeedGameSPLIT'], resolve),
                meta: {
                    title: 'SPLIT'
                }
            },
            {
                path: 'minionssadali',
                name: 'leisure_detail_minionssadali',
                component: resolve => require(['../views/leisuregame/children/MinionsGameSadali'], resolve),
                meta: {
                    title: '미니언즈 사다리'
                }
            },
            {
                path: 'minionsrullet',
                name: 'leisure_detail_minionsrullet',
                component: resolve => require(['../views/leisuregame/children/MinionsGameRullet'], resolve),
                meta: {
                    title: '미니언즈 룰렛'
                }
            },
            {
                path: 'pandasadali',
                name: 'leisure_detail_pandasadalil',
                component: resolve => require(['../views/leisuregame/children/PandaGameSadali'], resolve),
                meta: {
                    title: '판다 사다리'
                }
            },
            {
                path: 'pandacoin',
                name: 'leisure_detail_pandacoin',
                component: resolve => require(['../views/leisuregame/children/PandaGameCoin'], resolve),
                meta: {
                    title: '팬다 코인'
                }
            },
            {
                path: 'jwcasinoangel',
                name: 'leisure_detail_jwcasinoangel',
                component: resolve => require(['../views/leisuregame/children/JwCasinoAngel'], resolve),
                meta: {
                    title: 'Angel'
                }
            },
            {
                path: 'jwcasinoost',
                name: 'leisure_detail_jwcasinoost',
                component: resolve => require(['../views/leisuregame/children/JwCasinoOst'], resolve),
                meta: {
                    title: 'OST'
                }
            },
            {
                path: 'jwcasinosupermario',
                name: 'leisure_detail_jwcasinosupermario',
                component: resolve => require(['../views/leisuregame/children/JwCasinoSupermario'], resolve),
                meta: {
                    title: 'OST'
                }
            },
            {
                path: 'royalsutda',
                name: 'leisure_detail_royalsutda',
                component: resolve => require(['../views/leisuregame/children/RoyalSutda.vue'], resolve),
                meta: {
                    title: '로얄섯다'
                }
            }



        ],
        meta: {
            title: '미니게임'
        }
    },
]

const router = new VueRouter({
    /* mode: 'history',*/
    routes,
    linkExactActiveClass: 'acvice_menu',
})

/*method*/
const contains = function (needle, arr) {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] === needle) return true;
    }
    return false;
}
router.beforeEach((to, from, next) => {
    // let names = ['main', 'sports', 'sports_inplay']
    // if (contains(to.name, names)) {
    //     next()
    // } else {
    //     let isLogin = router.app.$store.state.isLogin;
    //     if (!isLogin) {
    //         router.app.$store.state.isShowLoginPanel = true;
    //         next({name: 'login', query: {t: Date.now()}})
    //     } else {
    //         next()
    //     }
    // }
    next()
});
const VueRouterPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}


export default router
