<template>
    <div style="display: flex;flex-direction: column;align-items: center;justify-content: center;flex-wrap: wrap;width: 100%">
        <div style="display: flex;flex-wrap: nowrap;justify-content: space-between;align-items: center;width: 100%;margin-bottom: 5px">
            <div style="width: 49.5%;" @click="payback">
                <img src="../assets/images/banner/dst/payback.png" alt="" style="width: 100%">
            </div>
            <div style="width: 49.5%;" @click="coffepay">
                <img src="../assets/images/banner/dst/coffe.png" alt="" style="width: 100%">
            </div>
        </div>
        <div style="display: flex;flex-wrap: nowrap;justify-content: space-between;align-items: center;width: 100%;margin-bottom: 5px">
            <div style="width: 49.5%;" @click="owlevent">
                <img src="../assets/images/banner/dst/owl.png" alt="" style="width: 100%">
            </div>
            <div style="width: 49.5%;"  @click="run369">
                <img src="../assets/images/banner/dst/run369.png" alt="" style="width: 100%">
            </div>
        </div>
    </div>
</template>

<script>
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../store/mutation-types";
    import {coffepay, owlevent, payback, run369} from "../network/userRequest";

    export default {
        name: "EventButtonsComp",
        methods:{
            payback() {
                this.$store.commit(RECEIVE_SHOW_LOADING);
                payback().then(res => {
                    if (res.data.success) {
                        this.$store.dispatch('actionUserInfo')
                        this.$swal({
                            title: '페이백 지급이 완료되였습니다',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING);
                })
            },
            coffepay() {
                this.$store.commit(RECEIVE_SHOW_LOADING);
                coffepay().then(res => {
                    if (res.data.success) {
                        this.$store.dispatch('actionUserInfo')
                        this.$swal({
                            title: '커피값&식사비 ' + res.data.msg + 'P 지급 완료\r\n\t\r\n\t' +
                                '커피값&식사비는 미니게임만 이용가능합니다 \n' +
                                '롤링300% 최소5회 배팅',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING);
                })
            },
            owlevent() {
                this.$store.commit(RECEIVE_SHOW_LOADING);
                owlevent().then(res => {
                    if (res.data.success) {
                        this.$store.dispatch('actionUserInfo')
                        this.$swal({
                            title: res.data.msg + 'P 지급 완료\r\n\t\r\n\t' +
                                '올빼미 이벤트란 규정 필수 숙지 후 이용바랍니다.\n',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING);
                })
            },
            run369() {
                this.$store.commit(RECEIVE_SHOW_LOADING);
                run369().then(res => {
                    if (res.data.success) {
                        this.$store.dispatch('actionUserInfo')
                        this.$swal({
                            title: res.data.msg + 'P 지급 완료\r\n\t\r\n\t' +
                                '[중요]돌발이벤트 규정 숙지 후 이용바랍니다.\n',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING);
                })
            },
        }
    }
</script>

<style scoped>

</style>