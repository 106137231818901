<template>
    <div class="left-menus">
        <div style="width: 100%;display: flex;align-items: center;justify-content: space-between;flex-wrap: nowrap">
            <router-link to="/recharge" tag="button"
                    style="width: 49.5%;height: 42px;font-size: 16px;font-weight: bold;border-radius: 3px;  background: linear-gradient(180deg, #2f2f2f, #727272, #3e3e3e);">충전</router-link>
            <router-link  to="/exchange" tag="button"
                          style="width: 49.5%;height: 42px;font-size: 16px;font-weight: bold;border-radius: 3px;   background: linear-gradient(180deg, #2f2f2f, #727272, #3e3e3e);">환전</router-link>
        </div>
        <div class="menu">
            <div class="title">
                <img src="../assets/images/icon/leftmenu/icon-01.png"/> 스포츠
            </div>
            <div class="buttons">
                <router-link tag="button" to="/sports" class="btn2ceil">
                    크로스
                </router-link>
                <router-link tag="button" to="/sports_ho" class="btn2ceil">
                    핸디캡
                </router-link>
            </div>
            <div class="buttons">
                <router-link tag="button" to="/sports_special" class="btn2ceil">
                    스페셜
                </router-link>
                <router-link tag="button" to="/sports_inplay" class="btn2ceil">
                    인플레이
                </router-link>
            </div>
        </div>

<!--        <div class="menu">-->
<!--            <div class="title">-->
<!--                <img src="../assets/images/icon/leftmenu/icon-02.png"/> 엔트리-->
<!--            </div>-->
<!--            <div class="buttons">-->
<!--                <router-link tag="button" to="/leisure/eospowerball1m" class="btn3ceil">-->
<!--                    EOS파워볼1분-->
<!--                </router-link>-->
<!--                <router-link tag="button" to="/leisure/eospowerball3m" class="btn3ceil">-->
<!--                    EOS파워볼3분-->
<!--                </router-link>-->
<!--                <router-link tag="button" to="/leisure/eospowerball5m" class="btn3ceil">-->
<!--                    EOS파워볼5분-->
<!--                </router-link>-->
<!--            </div>-->
<!--        </div>-->

        <div class="menu">
            <div class="title">
                <img src="../assets/images/icon/leftmenu/icon-03.png"/> 토큰게임
            </div>
            <div class="buttons">
                <router-link tag="button" to="/tgame365/tgame365highlow" class="btn3ceil">
                    토큰하이로우
                </router-link>
                <router-link tag="button" to="/tgame365/tgame365ruollet" class="btn3ceil">
                    토큰룰렛
                </router-link>
                <router-link tag="button" to="/tgame365/tgame365baccarat" class="btn3ceil">
                    토큰바카라
                </router-link>
            </div>
        </div>

        <div class="menu">
            <div class="title">
                <img src="../assets/images/icon/leftmenu/icon-04.png"/> BET365
            </div>
            <div class="buttons">
                <router-link tag="button" to="/leisure/bet365_superleague" class="btn2ceil">
                    가상축구
                </router-link>
                <router-link tag="button" to="/leisure/bet365_water" class="btn2ceil">
                    가상농구
                </router-link>
            </div>
            <div class="buttons">
                <router-link tag="button" to="/leisure/bet365_goldenhill" class="btn2ceil">
                    가상경견
                </router-link>
                <router-link tag="button" to="/leisure/bet365_horserace2" class="btn2ceil">
                    가상경마
                </router-link>
            </div>
        </div>

        <div class="menu">
            <div class="title">
                <img src="../assets/images/icon/leftmenu/icon-05.png"/> 보스코어
            </div>
            <div class="buttons">
                <router-link tag="button" to="/leisure/ssd1" class="btn3ceil">
                    별다리1분
                </router-link>
                <router-link tag="button" to="/leisure/ssd2" class="btn3ceil">
                    별다리2분
                </router-link>
                <router-link tag="button" to="/leisure/ssd3" class="btn3ceil">
                    별다리3분
                </router-link>
            </div>
        </div>

        <div class="menu">
            <div class="title">
                <img src="../assets/images/icon/leftmenu/icon-06.png"/> 게임스타
            </div>
            <div class="buttons">
                <router-link tag="button" to="/leisure/speedgamepdali" class="btn3ceil">
                    피그사다리
                </router-link>
                <router-link tag="button" to="/leisure/speedgamelm" class="btn3ceil">
                    라이언&무지
                </router-link>
                <router-link tag="button" to="/leisure/speedgamesplit" class="btn3ceil">
                    스플릿바카라
                </router-link>
            </div>
        </div>

        <div class="menu">
            <div class="title">
                <img src="../assets/images/icon/leftmenu/icon-07.png"/> JW게임
            </div>
            <div class="buttons">
                <router-link tag="button" to="/leisure/jwcasinoangel" class="btn3ceil">
                    천사&악마
                </router-link>
                <router-link tag="button" to="/leisure/jwcasinoost" class="btn3ceil">
                    타조게임
                </router-link>
                <router-link tag="button" to="/leisure/jwcasinosupermario" class="btn3ceil">
                    슈퍼마리오
                </router-link>
            </div>
        </div>

        <div class="menu">
            <div class="title">
                <img src="../assets/images/icon/leftmenu/icon-08.png"/> 스카이파크
            </div>
            <div class="buttons">
                <router-link tag="button" to="/leisure/sky_oddeven" class="btn3ceil">
                    홀짝
                </router-link>
                <router-link tag="button" to="/leisure/sky_speed_bakara" class="btn3ceil">
                    바카라
                </router-link>
                <router-link tag="button" to="/leisure/sky_dice" class="btn3ceil">
                    주사위
                </router-link>
            </div>
        </div>

        <div class="menu">
            <div class="title">
                <img src="../assets/images/icon/leftmenu/icon-09.png"/> 카드게임
            </div>
            <div class="buttons">
                <router-link tag="button" to="/leisure/candy_dragontiger" class="btn4ceil">
                    드래곤타이거
                </router-link>

            </div>
        </div>
      <div class="menu">
        <div class="title">
          <img src="../assets/images/icon/leftmenu/icon-10.png"/> 섯다게임
        </div>
        <div class="buttons">
          <router-link tag="button" to="/leisure/royalsutda" class="btn4ceil">
            로얄섯다
          </router-link>
        </div>
      </div>


    </div>
</template>

<script>
    export default {
        name: "LeftBarMenusComp",
        methods:{

        }
    }
</script>

<style scoped>
    .left-menus{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
    }
    .left-menus .menu{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 5px;
    }
    .left-menus .menu .title{
        width: 100%;
        height: 50px;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 18px;
        font-weight: bold;
        border-bottom: 1px solid #6b6b6c;
    }
    .left-menus .menu img{
      width: 40px;
    }
    .left-menus .menu .buttons{
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        margin: 5px 0 0 0;
    }
    .left-menus .menu .buttons button{
        height: 34px;
        background: linear-gradient(180deg, #525252, #727272, #525252);
        border-radius: 2px;
        border: 1px solid #747474;
        font-weight: bold;
        transition: .2s;
        color: #d0cfd0;
    }
    .left-menus .menu .buttons button:hover{
       --background: linear-gradient(180deg, #7b7b7b, #3d3d3d, #7b7b7b);
        color: #ffffff;
    }
    .left-menus .menu .btn2ceil{
        width: 49.3%;
    }
    .left-menus .menu .btn3ceil{
        width: 33%;
    }
    .left-menus .menu .btn4ceil{
      width:99%;
    }

    @media screen and (max-width: 1024px) {

    }
</style>